import React from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql, Link } from 'gatsby';
// import { withPrefix }, Link from 'gatsby-link';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import Img from 'components/Elements/Img';
import logger from 'utils/logger';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

import LoadingView from 'components/Loaders/LoadingView';
import { Sticky } from 'react-sticky';
import { CardContact } from 'components/Cards/Cards';
import SimpleHeader from 'components/Headers/SimpleHeader';

class ArticleSingle extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.contentfulPage
    ) { return <LoadingView />; }
    const page = data.contentfulPage;

    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO title={page.title} />
          <SimpleHeader title={page.title} />
          <section>
            <Offset>
              <article className={`page`}>
                <main className={`ptb-30em page-entry`} dangerouslySetInnerHTML={{ __html: page.content.childMarkdownRemark.html }} />
              </article>
            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      id
      ... Page
    }
  }
`;
export default ArticleSingle;
